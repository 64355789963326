import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { environment } from '../../../../environments/environment';
import * as layoutActions from '../layout/layout.actions';
import { AssetEvent, RequestAsset } from '../models';
import * as assetActions from './asset.actions';
import { Asset, ASSET_ATTACHMENT_TYPE } from './asset.model';
import * as pluralize from 'pluralize';
import { downloadCSV } from '../utils';

export let CACHE_SECONDS = 15 * 60;
if (environment.test) {
    CACHE_SECONDS = 1;
}

interface FilterResults {
    columnFilters: ColumnFilterOptions;
    columnFiltersForReview?: ColumnFilterOptions;
}

interface ListResults {
    assets: Asset[];
    totalCount: number;
}

interface RequestListResults extends ListResults {
    requestAssets: RequestAsset[];
}

@Injectable()
export class AssetEffects {
    add$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.ADD),
            map((action: assetActions.AddAction) => action.payload),
            mergeMap(({ assets, tableType, files, project_currency_id }) => {
                const formData = this.buildAssetFormData(assets, files, project_currency_id);
                const headers = this.buildHeaderOptions();
                return this.http.post<Asset[]>(`/api/assets`, formData, { headers }).pipe(
                    map(res => res.map((a) => new Asset(a))),
                    map((createdAssets: Asset[]) => {
                        if (assets.length === 1 && assets[0].event_id) {
                            if (createdAssets[0].assetEvents !== null) {
                                createdAssets[0].assetEvents.map(ae => new AssetEvent(ae));
                            }
                            return new assetActions.AddForEventCompleteAction({ assets: createdAssets, eventId: assets[0].event_id });
                        }
                        return new assetActions.AddCompleteAction({ assets: createdAssets, tableType });
                    }),
                    catchError((error) => observableOf(new assetActions.AddFailedAction({ error }))));
            })));

    duplicate$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.DUPLICATE_ASSET),
            map((action: assetActions.DuplicateAssetAction) => action.payload),
            mergeMap(({ assetId, copies }) => {
                return this.http.put<Asset[]>(`/api/assets/${assetId}`, { copies }).pipe(
                    map(res => res.map((a) => new Asset(a))),
                    map((createdAssets: Asset[]) => new assetActions.DuplicateAssetCompleteAction({ assetId, assets: createdAssets })),
                    catchError((error) => observableOf(new assetActions.DuplicateAssetFailedAction({ error }))));
            })));

    addFromTransactions$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.ADD_FROM_TRANSACTION),
            map((action: assetActions.AddFromTransactionAction) => action.payload),
            mergeMap(({ transaction_list, properties }) => {
                return this.http.patch<Asset[]>(`/api/assets-from-transaction`, { transaction_list, properties }).pipe(
                    map(() => new assetActions.AddFromTransactionCompleteAction()),
                    catchError((error) => observableOf(new assetActions.AddFromTransactionFailedAction({ error }))));
            })));

    removeTransactions$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.REMOVE_TRANSACTIONS),
            map((action: assetActions.RemoveTransactionsAction) => action.payload),
            mergeMap((payload) => this.http.post(`/api/assets/${payload.assetId}/remove-transactions`, payload.transactionIds).pipe(
                map(() => new assetActions.RemoveTransactionsCompleteAction(payload)),
                catchError((error) => observableOf(new assetActions.RemoveTransactionsFailedAction({ error }))))
            )));

    update$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.UPDATE),
            map((action: assetActions.UpdateAction) => action.payload),
            mergeMap((payload) => {
                return this.http.put<any>(`/api/studio/assets`, payload).pipe(
                    map((result) => new assetActions.UpdateMultipleCompleteAction({ assets: result.updatedAssets.map((a) => new Asset(a)), columnFilters: result.columnFilters, warning: result?.warning })),
                    catchError((error) => observableOf(new assetActions.UpdateMultipleFailedAction({ error }))))
            })));

    updateMultiple$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.UPDATE_MULTIPLE),
            map((action: assetActions.UpdateMultipleAction) => action.payload),
            mergeMap((payload) => {
                return this.http.patch<any>(`/api/studio/${payload.studioId}/assets`, payload).pipe(
                    map((result) => new assetActions.UpdateMultipleCompleteAction({ assets: result.updatedAssets.map((a) => new Asset(a)), columnFilters: result.columnFilters, warning: result?.warning })),
                    catchError((error) => observableOf(new assetActions.UpdateMultipleFailedAction({ error }))))
            })));

    listByFranchise$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_FRANCHISE),
            map((action: assetActions.ListByFranchiseAction) => action.payload),
            switchMap((payload) => this.http.post<ListResults>(`/api/franchises/${payload.franchiseId}/assets`, payload.options).pipe(
                map((result) => {
                    const assets = result.assets.map((asset) => new Asset(asset));
                    return new assetActions.ListByFranchiseCompleteAction({ franchiseId: payload.franchiseId, totalCount: result.totalCount, assets, options: payload.options });
                }),
                catchError((error) => observableOf(new assetActions.ListByFranchiseFailedAction({ error }))))
            )));

    listByFranchiseFilters$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_FRANCHISE_FILTERS),
            map((action: assetActions.ListByFranchiseFiltersAction) => action.payload),
            switchMap((payload) => this.http.post<FilterResults>(`/api/franchises/${payload.franchiseId}/assets/filters`, { assetTypes: payload.assetTypes }).pipe(
                map((result) => {
                    return new assetActions.ListByFranchiseFiltersCompleteAction({ franchiseId: payload.franchiseId, columnFilters: result.columnFilters, columnFiltersForReview: result.columnFiltersForReview });
                }),
                catchError((error) => observableOf(new assetActions.ListByFranchiseFiltersFailedAction({ error }))))
            )));

    listByDivision$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_DIVISION),
            map((action: assetActions.ListByDivisionAction) => action.payload),
            switchMap((payload): Observable<assetActions.ListByDivisionCompleteAction | assetActions.ListByDivisionFailedAction> => {
                return this.http.post<ListResults>(`/api/divisions/${payload.divisionId}/assets`, payload.options).pipe(
                    map((results) => {
                        const assets = results.assets.map((asset) => new Asset(asset));
                        return new assetActions.ListByDivisionCompleteAction({ divisionId: payload.divisionId, totalCount: results.totalCount, assets, options: payload.options });
                    }),
                    catchError((error) => observableOf(new assetActions.ListByDivisionFailedAction({ error }))));
            })
        ));

    listByDivisionFilters$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_DIVISION_FILTERS),
            map((action: assetActions.ListByDivisionFiltersAction) => action.payload),
            switchMap((payload) => this.http.post<FilterResults>(`/api/divisions/${payload.divisionId}/assets/filters`, { assetTypes: payload.assetTypes }).pipe(
                map((result) => {
                    return new assetActions.ListByDivisionFiltersCompleteAction({ divisionId: payload.divisionId, columnFilters: result.columnFilters });
                }),
                catchError((error) => observableOf(new assetActions.ListByDivisionFiltersFailedAction({ error }))))
            )));

    listByEvent$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_EVENT),
            map((action: assetActions.ListByEventAction) => action.payload),
            switchMap((payload) => this.http.post<ListResults>(`/api/events/${payload.eventId}/list-assets`, payload.options).pipe(
                map((result) => {
                    const assets = result.assets.map((asset) => new Asset(asset));
                    return new assetActions.ListByEventCompleteAction({ eventId: payload.eventId, totalCount: result.totalCount, assets, options: payload.options });
                }),
                catchError((error) => observableOf(new assetActions.ListByEventFailedAction({ error }))))
            )));

    listByEventFilters$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_EVENT_FILTERS),
            map((action: assetActions.ListByEventFiltersAction) => action.payload),
            switchMap((payload) => this.http.get<FilterResults>(`/api/events/${payload.eventId}/list-assets/filters`).pipe(
                map((result) => {
                    return new assetActions.ListByEventFiltersCompleteAction({ eventId: payload.eventId, columnFilters: result.columnFilters });
                }),
                catchError((error) => observableOf(new assetActions.ListByEventFiltersFailedAction({ error }))))
            )));

    listByFinalApproverRequest$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_FINAL_APPROVER_REQUEST),
            map((action: assetActions.ListByFinalApproverRequestAction) => action.payload),
            switchMap((payload) => this.http.post<{ assets: Asset[], requestAssets: RequestAsset[] }>(`/api/final-approver-request/request/${payload.request_id}/assets`, payload.options).pipe(
                map((res: { assets: Asset[], requestAssets: RequestAsset[], totalCount: number }) => new assetActions.ListByFinalApproverRequestCompleteAction({ assets: res.assets ? res.assets.map(a => new Asset(a)) : [], requestAssets: res.requestAssets ? res.requestAssets.map(r => new RequestAsset(r)) : [], requestId: payload.request_id, totalCount: res.totalCount, options: payload.options })),
                catchError((error) => observableOf(new assetActions.ListByFinalApproverRequestFailedAction({ error }))))
            )));

    listByFinalApproverRequestFilters$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_FINAL_APPROVER_REQUEST_FILTERS),
            map((action: assetActions.ListByFinalApproverRequestFiltersAction) => action.payload),
            switchMap((payload) => this.http.get<FilterResults>(`/api/final-approver-request/request/${payload.request_id}/assets/filters`).pipe(
                map((result) => {
                    return new assetActions.ListByFinalApproverRequestFiltersCompleteAction({ requestId: payload.request_id, columnFilters: result.columnFilters });
                }),
                catchError((error) => observableOf(new assetActions.ListByFinalApproverRequestFiltersFailedAction({ error }))))
            )));

    listByGroup$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_GROUP),
            map((action: assetActions.ListByGroupAction) => action.payload),
            switchMap((payload) => this.http.post<ListResults>(`/api/groups/${payload.groupId}/assets`, {
                trashed: false,
                ...payload.options
            }).pipe(
                map((result) => {
                    const assets = result.assets.map((asset) => new Asset(asset));
                    return new assetActions.ListByGroupCompleteAction({ groupId: payload.groupId, totalCount: result.totalCount, assets, options: payload.options });
                }),
                catchError((error) => observableOf(new assetActions.ListByGroupFailedAction({ error }))))
            )));

    listByGroupAndSubGroups$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_GROUP_AND_SUB_GROUPS),
            map((action: assetActions.ListByGroupAndSubGroupAction) => action.payload),
            switchMap((payload) => this.http.post<ListResults>(`/api/groups-and-sub-groups/${payload.groupId}/assets`, {
                trashed: false,
                ...payload.options
            }).pipe(
                map((result) => {
                    const assets = result.assets.map((asset) => new Asset(asset));
                    return new assetActions.ListByGroupCompleteAction({ groupId: payload.groupId, totalCount: result.totalCount, assets, options: payload.options });
                }),
                catchError((error) => observableOf(new assetActions.ListByGroupFailedAction({ error }))))
            )));

    listByGroupFilters$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_GROUP_FILTERS),
            map((action: assetActions.ListByGroupFiltersAction) => action.payload),
            switchMap((payload) => this.http.post<FilterResults>(`/api/groups/${payload.groupId}/assets/filters`, { options: payload.options }).pipe(
                map((result) => {
                    return new assetActions.ListByGroupFiltersCompleteAction({ groupId: payload.groupId, columnFilters: result.columnFilters });
                }),
                catchError((error) => observableOf(new assetActions.ListByGroupFiltersFailedAction({ error }))))
            )));

    listByRequest$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_REQUEST),
            map((action: assetActions.ListByRequestAction) => action.payload),
            switchMap((payload) => this.http.post<RequestListResults>(`/api/request/${payload.request_id}/assets`, payload.options).pipe(
                map((res) => {
                    const assets = res.assets.map((asset) => new Asset(asset));
                    const requestAssets = res.requestAssets.map(ra => new RequestAsset(ra));
                    return new assetActions.ListByRequestCompleteAction({ requestId: payload.request_id, totalCount: res.totalCount, assets, requestAssets, options: payload.options });
                }),
                catchError((error) => observableOf(new assetActions.ListByRequestFailedAction({ error }))))
            )));

    listByRequestFilters$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_REQUEST_FILTERS),
            map((action: assetActions.ListByRequestFiltersAction) => action.payload),
            switchMap((payload) => this.http.get<FilterResults>(`/api/request/${payload.request_id}/assets/filters`).pipe(
                map((result) => {
                    return new assetActions.ListByRequestFiltersCompleteAction({ requestId: payload.request_id, columnFilters: result.columnFilters });
                }),
                catchError((error) => observableOf(new assetActions.ListByRequestFiltersFailedAction({ error }))))
            )));

    listByStudio$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_STUDIO),
            map((action: assetActions.ListByStudioAction) => action.payload),
            switchMap((payload) => this.http.post<ListResults>(`/api/studios/${payload.studioId}/assets`, payload.options).pipe(
                map((result) => {
                    const assets = result.assets.map((asset) => new Asset(asset));
                    return new assetActions.ListByStudioCompleteAction({ studioId: payload.studioId, totalCount: result.totalCount, assets, options: payload.options });
                }),
                catchError((error) => observableOf(new assetActions.ListByStudioFailedAction({ error }))))
            )));

    listByStudioFilters$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_STUDIO_FILTERS),
            map((action: assetActions.ListByStudioFiltersAction) => action.payload),
            switchMap((payload) => this.http.get<FilterResults>(`/api/studios/${payload.studioId}/assets/filters`).pipe(
                map((result) => {
                    return new assetActions.ListByStudioFiltersCompleteAction({ studioId: payload.studioId, columnFilters: result.columnFilters });
                }),
                catchError((error) => observableOf(new assetActions.ListByStudioFiltersFailedAction({ error }))))
            )));

    get$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.GET),
            map((action: assetActions.GetAction) => action.payload),
            switchMap((assetId) => this.http.get(`/api/assets/${assetId}`).pipe(
                map(res => new Asset(res)),
                map((ass: Asset) => new assetActions.GetCompleteAction(ass)),
                catchError((error) => observableOf(new assetActions.GetFailedAction({ error }))))
            )));

    matchAssets$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.MATCH_ASSETS),
            map((action: assetActions.MatchAssetsAction) => action.payload),
            switchMap((payload) => this.http.post<any>(`/api/transactions/match-assets`, payload).pipe(
                map((res) => new assetActions.MatchAssetsCompleteAction(res)),
                catchError((error) => observableOf(new assetActions.MatchAssetsFailedAction({ error }))))
            )));

    mergeAssets$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.MERGE_ASSETS),
            map((action: assetActions.MergeAssetsAction) => action.payload),
            switchMap((payload) => this.http.put<Asset>(`/api/assets/merge-assets`, { asset: payload.asset, assetIds: payload.assetIds }).pipe(
                map((asset: Asset) => new assetActions.MergeAssetsCompleteAction({ asset: new Asset(asset), assetIds: payload.assetIds, tableType: payload.tableType })),
                catchError((error) => observableOf(new assetActions.MergeAssetsFailedAction({ error }))))
            )));

    deletePhoto$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.DELETE_PHOTO),
            map((action: assetActions.DeletePhotoAction) => action.payload),
            mergeMap((photoData) => this.http.delete(`/api/photos/${photoData.photo.id}`).pipe(
                map(() => new assetActions.DeletePhotoCompleteAction(photoData.photo)),
                catchError((error) => observableOf(new assetActions.DeletePhotoFailedAction({ error }))))
            )));

    deletePhotoComplete$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.DELETE_PHOTO_COMPLETE),
            map(() => new layoutActions.InfoAction({ message: 'Photo Deleted' }))));

    addPhotoComplete$ = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.ADD_PHOTO),
            map((action: any) => action.payload),
            map(({ photos }) => new layoutActions.InfoAction({ message: `${photos.length} ${pluralize('photo', photos.length)} successfully added.` }))));

    addPhotosComplete$ = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.ADD_PHOTOS),
            map((action: any) => action.payload),
            map(({ assets, photos }) => new layoutActions.InfoAction({ message: `${photos.length / assets.length} ${pluralize('photo', photos.length / assets.length)} successfully added.` }))));

    downloadPhoto$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.DOWNLOAD_PHOTO),
            map((action: assetActions.DownloadPhotoAction) => action.payload),
            switchMap((photoData) => this.http.get(`/api/photos/${photoData.photo.id}/download`, { observe: 'response', responseType: 'blob' }).pipe(
                map((res) => {
                    if (res) {
                        const fileName = res.headers ? res.headers.get('content-disposition').split('=')[1] : 'image.jpg';
                        return new assetActions.DownloadPhotoCompleteAction({ photo: res.body, fileName });
                    } else {
                        return new assetActions.DownloadPhotoCompleteAction({ photo: new Blob(), fileName: 'image.jpg' });
                    }
                }),
                catchError((error) => observableOf(new assetActions.DownloadPhotoFailedAction({ error }))))
            )));

    downloadPhotoComplete$ = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.DOWNLOAD_PHOTO_COMPLETE),
            map((action: assetActions.DownloadPhotoCompleteAction) => {
                saveAs(action.payload.photo, action.payload.fileName);
                return action.payload.fileName;
            }),
            map((filename) => new layoutActions.InfoAction({ message: `Downloaded photo ${filename}` }))));

    rotatePhoto$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.ROTATE_PHOTO),
            map((action: assetActions.RotatePhotoAction) => action.payload),
            switchMap((payload) => this.http.put(`/api/photos/${payload.photo.id}/rotate`, { rotation: payload.rotation }).pipe(
                map(res => new Asset(res)),
                map((asset) => new assetActions.RotatePhotoCompleteAction(asset)),
                catchError((error) => observableOf(new assetActions.RotatePhotoFailedAction({ error }))))
            )));

    setMainPhoto$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.SET_MAIN_PHOTO),
            map((action: assetActions.SetMainPhotoAction) => action.payload),
            switchMap((data) => this.http.put<Asset>(`/api/assets/${data.asset_id}/main_photo`, { photo_id: data.photo_id }).pipe(
                map(res => new Asset(res)),
                map((asset) => new assetActions.SetMainPhotoCompleteAction(asset)),
                catchError((error) => observableOf(new assetActions.SetMainPhotoFailedAction({ error }))))
            )));

    requestFailed$ = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.UPDATE_FIELD_MULTIPLE_FAILED, assetActions.UPDATE_MULTIPLE_FAILED, assetActions.GET_FAILED, assetActions.ADD_FAILED, assetActions.LIST_BY_FRANCHISE_FAILED, assetActions.LIST_BY_FRANCHISE_FILTERS_FAILED, assetActions.LIST_BY_DIVISION_FAILED, assetActions.LIST_BY_DIVISION_FILTERS_FAILED, assetActions.LIST_BY_EVENT_FAILED, assetActions.LIST_BY_EVENT_FILTERS_FAILED, assetActions.LIST_BY_FINAL_APPROVER_REQUEST_FAILED, assetActions.LIST_BY_FINAL_APPROVER_REQUEST_FILTERS_FAILED, assetActions.LIST_BY_GROUP_FAILED, assetActions.LIST_BY_GROUP_FILTERS_FAILED, assetActions.LIST_BY_REQUEST_FAILED, assetActions.LIST_BY_REQUEST_FILTERS_FAILED, assetActions.LIST_BY_STUDIO_FAILED, assetActions.LIST_BY_STUDIO_FILTERS_FAILED, assetActions.SET_MAIN_PHOTO_FAILED, assetActions.DELETE_PHOTO_FAILED, assetActions.ROTATE_PHOTO_FAILED, assetActions.DOWNLOAD_PHOTO_FAILED, assetActions.REMOVE_TRANSACTIONS_FAILED,
                assetActions.DUPLICATE_ASSET_FAILED, assetActions.MERGE_ASSETS_FAILED),
            map((action: any) => action.payload),
            map((payload) => new layoutActions.ErrorAction(payload))));

    updateFieldMultiple$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.UPDATE_FIELD_MULTIPLE),
            map((action: assetActions.UpdateFieldMultipleAction) => action.payload),
            mergeMap((payload) => this.http.put<Asset[]>(`/api/studio/${payload.studioId}/assets-field`, payload).pipe(
                map(res => res.map((a) => new Asset(a))),
                map((assets: Asset[]) => new assetActions.UpdateFieldMultipleCompleteAction(assets)),
                catchError((error) => observableOf(new assetActions.UpdateFieldMultipleFailedAction({ error }))))
            )));

    listBySubGroup$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_SUB_GROUP),
            map((action: assetActions.ListBySubGroupAction) => action.payload),
            switchMap(({ subGroupId, options }) => this.http.post<ListResults>(`/api/sub-groups/${subGroupId}/assets`, {
                trashed: false,
                ...options
            }).pipe(
                map((result) => {
                    const assets = result.assets.map((asset) => new Asset(asset));
                    return new assetActions.ListBySubGroupCompleteAction({ subGroupId: subGroupId, totalCount: result.totalCount, assets, options: options });
                }),
                catchError((error) => observableOf(new assetActions.ListBySubGroupFailedAction({ error }))))
            )));

    listBySubGroupFilters$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.LIST_BY_SUB_GROUP_FILTERS),
            map((action: assetActions.ListBySubGroupFiltersAction) => action.payload),
            switchMap((payload) => this.http.post<FilterResults>(`/api/sub-groups/${payload.subGroupId}/assets/filters`, { options: payload.options }).pipe(
                map((result) => {
                    return new assetActions.ListBySubGroupFiltersCompleteAction({ subGroupId: payload.subGroupId, columnFilters: result.columnFilters });
                }),
                catchError((error) => observableOf(new assetActions.ListBySubGroupFiltersFailedAction({ error }))))
            )));

    exportCsvByStudio$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.EXPORT_CSV_BY_STUDIO),
            map((action: assetActions.ExportCsvStudioAction) => action.payload),
            switchMap((payload) => this.http.post(`/api/studios/${payload.studioId}/exportcsv`, payload.options, { responseType: 'text' }).pipe(
                map((data) => downloadCSV(data)),
                map(() => new assetActions.ExportCsvStudioCompleteAction({})),
                catchError((error) => observableOf(new assetActions.ExportCsvStudioFailedAction({ error }))))
            )));

    exportCsvByDivision$ = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.EXPORT_CSV_BY_DIVISION),
            map((action: assetActions.ExportCsvDivisionAction) => action.payload),
            switchMap((payload) => {
                return this.http.post(`/api/divisions/${payload.divisionId}/exportcsv`, payload.options, { responseType: 'text' }).pipe(
                    map((data) => downloadCSV(data)),
                    map(() => new assetActions.ExportCsvDivisionCompleteAction({})),
                    catchError((error) => observableOf(new assetActions.ExportCsvDivisionFailedAction({ error })))
                )
            })
        ))

    exportCsvByFranchise$ = createEffect(() => this.actions$
        .pipe(
            ofType(assetActions.EXPORT_CSV_BY_FRANCHISE),
            map((action: assetActions.ExportCsvFranchiseAction) => action.payload),
            switchMap((payload) => {
                return this.http.post(`/api/franchises/${payload.franchiseId}/exportcsv`, payload.options, { responseType: 'text' }).pipe(
                    map((data) => downloadCSV(data)),
                    map(() => new assetActions.ExportCsvFranchiseCompleteAction({})),
                    catchError((error) => observableOf(new assetActions.ExportCsvFranchiseFailedAction({ error })))
                )
            })
        ));

    constructor(private actions$: Actions, private http: HttpClient) { }

    buildAssetFormData(assets, files?, project_currency_id?) {
        const formData = new FormData();
        formData.append('assets', JSON.stringify(assets));
        let f, appendName: string;
        if (files) {
            for (let i = 0; i < files.length; i++) {
                f = files[i];
                for (const val of ASSET_ATTACHMENT_TYPE) {
                    appendName = val + i + '[]';
                    for (const file of f[val]) {
                        formData.append(appendName, file, file.name);
                    }
                }
            }
        }
        if (project_currency_id) {
            formData.append('project_currency_id', JSON.stringify(project_currency_id));
        }
        return formData;
    }

    buildHeaderOptions() {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return headers;
    }
}
