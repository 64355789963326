import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ListOptions } from '../../../../../shared/types';
import { ColumnFilterOptions } from '../../../../../shared/types/column-filter-options';
import { Asset, Photo, RequestAsset } from '../models';
import { TableTypes } from '../types';
import { AssetTypes } from './asset.model';

export const ADD = '[Asset] Add';
export const ADD_COMPLETE = '[Asset] Add Complete';
export const ADD_FOR_EVENT_COMPLETE = '[Asset] Add For Event Complete';
export const ADD_FAILED = '[Asset] Add Failed';

export const ADD_FROM_TRANSACTION = '[Asset] Add From Transaction';
export const ADD_FROM_TRANSACTION_COMPLETE = '[Asset] Add From Transaction Complete';
export const ADD_FROM_TRANSACTION_FAILED = '[Asset] Add From Transaction Failed';

export const ADD_PHOTO = '[Asset] Add Photo';
export const ADD_PHOTOS = '[Asset] Add Photos';

export const DELETE_PHOTO = '[Asset] Delete Photo';
export const DELETE_PHOTO_COMPLETE = '[Asset] Delete Photo Complete';
export const DELETE_PHOTO_FAILED = '[Asset] Delete Photo Failed';

export const DOWNLOAD_PHOTO = '[Asset] Download Photo';
export const DOWNLOAD_PHOTO_COMPLETE = '[Asset] Download Photo Complete';
export const DOWNLOAD_PHOTO_FAILED = '[Asset] Download Photo Failed';

export const DUPLICATE_ASSET = '[Asset] Duplicate Asset';
export const DUPLICATE_ASSET_COMPLETE = '[Asset] Duplicate Asset Complete';
export const DUPLICATE_ASSET_FAILED = '[Asset] Duplicate Asset Failed';

export const ROTATE_PHOTO = '[Asset] Rotate Photo';
export const ROTATE_PHOTO_COMPLETE = '[Asset] Rotate Photo Complete';
export const ROTATE_PHOTO_FAILED = '[Asset] Rotate Photo Failed';

export const SET_MAIN_PHOTO = '[Asset] Set Main Photo';
export const SET_MAIN_PHOTO_COMPLETE = '[Asset] Set Main Photo Complete';
export const SET_MAIN_PHOTO_FAILED = '[Asset] Set Main Photo Failed';

export const GET = '[Asset] Get';
export const GET_COMPLETE = '[Asset] Get Complete';
export const GET_FAILED = '[Asset] Get Failed';

export const LIST_BY_FRANCHISE = '[Asset] List By Franchise';
export const LIST_BY_FRANCHISE_COMPLETE = '[Asset] List By Franchise Complete';
export const LIST_BY_FRANCHISE_FAILED = '[Asset] List By Franchise Failed';

export const LIST_BY_FRANCHISE_FILTERS = '[Asset] List By Franchise Filters';
export const LIST_BY_FRANCHISE_FILTERS_COMPLETE = '[Asset] List By Franchise Filters Complete';
export const LIST_BY_FRANCHISE_FILTERS_FAILED = '[Asset] List By Franchise Filters Failed';

export const LIST_BY_DIVISION = '[Asset] List By Division';
export const LIST_BY_DIVISION_COMPLETE = '[Asset] List By Division Complete';
export const LIST_BY_DIVISION_FAILED = '[Asset] List By Division Failed';

export const LIST_BY_DIVISION_FILTERS = '[Asset] List By Division Filters';
export const LIST_BY_DIVISION_FILTERS_COMPLETE = '[Asset] List By Division Filters Complete';
export const LIST_BY_DIVISION_FILTERS_FAILED = '[Asset] List By Division Filters Failed';

export const LIST_BY_EVENT = '[Asset] List By Event';
export const LIST_BY_EVENT_COMPLETE = '[Asset] List By Event Complete';
export const LIST_BY_EVENT_FAILED = '[Asset] List By Event Failed';

export const LIST_BY_EVENT_FILTERS = '[Asset] List By Event Filters';
export const LIST_BY_EVENT_FILTERS_COMPLETE = '[Asset] List By Event Filters Complete';
export const LIST_BY_EVENT_FILTERS_FAILED = '[Asset] List By Event Filters Failed';

export const LIST_BY_FINAL_APPROVER_REQUEST = '[Asset] List By Final Approver Request';
export const LIST_BY_FINAL_APPROVER_REQUEST_COMPLETE = '[Asset] List By Final Approver Request Complete';
export const LIST_BY_FINAL_APPROVER_REQUEST_FAILED = '[Asset] List By Final Approver Request Failed';

export const LIST_BY_FINAL_APPROVER_REQUEST_FILTERS = '[Asset] List By Final Approver Request Filters';
export const LIST_BY_FINAL_APPROVER_REQUEST_FILTERS_COMPLETE = '[Asset] List By Final Approver Request Filters Complete';
export const LIST_BY_FINAL_APPROVER_REQUEST_FILTERS_FAILED = '[Asset] List By Final Approver Request Filters Failed';

export const LIST_BY_GROUP = '[Asset] List By Group';
export const LIST_BY_GROUP_COMPLETE = '[Asset] List By Group Complete';
export const LIST_BY_GROUP_FAILED = '[Asset] List By Group Failed';

export const LIST_BY_GROUP_FILTERS = '[Asset] List By Group Filters';
export const LIST_BY_GROUP_FILTERS_COMPLETE = '[Asset] List By Group Filters Complete';
export const LIST_BY_GROUP_FILTERS_FAILED = '[Asset] List By Group Filters Failed';

export const LIST_BY_REQUEST = '[Asset] List By Request';
export const LIST_BY_REQUEST_COMPLETE = '[Asset] List By Request Complete';
export const LIST_BY_REQUEST_FAILED = '[Asset] List By Request Failed';

export const LIST_BY_REQUEST_FILTERS = '[Asset] List By Request Filters';
export const LIST_BY_REQUEST_FILTERS_COMPLETE = '[Asset] List By Request Filters Complete';
export const LIST_BY_REQUEST_FILTERS_FAILED = '[Asset] List By Request Filters Failed';

export const LIST_BY_STUDIO = '[Asset] List By Studio';
export const LIST_BY_STUDIO_COMPLETE = '[Asset] List By Studio Complete';
export const LIST_BY_STUDIO_FAILED = '[Asset] List By Studio Failed';

export const LIST_BY_STUDIO_FILTERS = '[Asset] List By Studio Filters';
export const LIST_BY_STUDIO_FILTERS_COMPLETE = '[Asset] List By Studio Filters Complete';
export const LIST_BY_STUDIO_FILTERS_FAILED = '[Asset] List By Studio Filters Failed';

export const MATCH_ASSETS = '[Transaction] Match Assets';
export const MATCH_ASSETS_COMPLETE = '[Transaction] Match Assets Complete';
export const MATCH_ASSETS_FAILED = '[Transaction] Match Assets Failed';

export const MERGE_ASSETS = '[Asset] Merge Assets';
export const MERGE_ASSETS_COMPLETE = '[Asset] Merge Assets Complete';
export const MERGE_ASSETS_FAILED = '[Asset] Merge Assets Failed';

export const REMOVE_TRANSACTIONS = '[Asset] Remove Transactions';
export const REMOVE_TRANSACTIONS_COMPLETE = '[Asset] Remove Transactions Complete';
export const REMOVE_TRANSACTIONS_FAILED = '[Asset] Remove Transactions Failed';

export const UPDATE = '[Asset] Update';
export const UPDATE_COMPLETE = '[Asset] Update Complete';
export const UPDATE_FAILED = '[Asset] Update Failed';

export const UPDATE_MULTIPLE = '[Asset] Update Multiple';
export const UPDATE_MULTIPLE_COMPLETE = '[Asset] Update Multiple Complete';
export const UPDATE_MULTIPLE_FAILED = '[Asset] Update Multiple Failed';

export const SELECT = '[Asset] Select';
export const SELECT_INLINE_EDIT = '[Asset] Select Inline Edit Asset';

export const SELECT_ASSETS_SHOWN = '[Asset] Select Asset Types to Show';

export const SET_ASSET_LIMIT = '[Asset] Set Asset Limit';

export const UPDATE_FIELD_MULTIPLE = '[Asset] Update Field Multiple';
export const UPDATE_FIELD_MULTIPLE_COMPLETE = '[Asset] Update Field Multiple Complete';
export const UPDATE_FIELD_MULTIPLE_FAILED = '[Asset] Update Field Multiple Failed';

export const LIST_BY_SUB_GROUP = '[Asset] List By Sub Group';
export const LIST_BY_SUB_GROUP_COMPLETE = '[Asset] List By Sub Group Complete';
export const LIST_BY_SUB_GROUP_FAILED = '[Asset] List By Sub Group Failed';

export const LIST_BY_SUB_GROUP_FILTERS = '[Asset] List By Sub Group Filters';
export const LIST_BY_SUB_GROUP_FILTERS_COMPLETE = '[Asset] List By Sub Group Filters Complete';
export const LIST_BY_SUB_GROUP_FILTERS_FAILED = '[Asset] List By Sub Group Filters Failed';

export const LIST_BY_GROUP_AND_SUB_GROUPS = '[Asset] List By Group And Sub Group';
export const LIST_BY_GROUP_AND_SUB_GROUPS_COMPLETE = '[Asset] List By Group And Sub Group Complete';
export const LIST_BY_GROUP_AND_SUB_GROUPS_FAILED = '[Asset] List By Group And Sub Group Failed';

export const EXPORT_CSV_BY_STUDIO = '[Asset] Export CSV Studio All Assets';
export const EXPORT_CSV_BY_STUDIO_COMPLETE = '[Asset] Export CSV Studio All Complete';
export const EXPORT_CSV_BY_STUDIO_FAILED = '[Asset] Export CSV Studio All Failed';

export const EXPORT_CSV_BY_FRANCHISE = '[Asset] Export CSV Feature All Assets';
export const EXPORT_CSV_BY_FRANCHISE_COMPLETE = '[Asset] List By Feature Complete';
export const EXPORT_CSV_BY_FRANCHISE_FAILED = '[Asset] List By FEATURE Failed';

export const EXPORT_CSV_BY_DIVISION = '[Asset] Export CSV Division All Assets';
export const EXPORT_CSV_BY_DIVISION_COMPLETE = '[Asset] List By Division Complete';
export const EXPORT_CSV_BY_DIVISION_FAILED = '[Asset] List By Division Failed';


export class AddAction implements Action {
    readonly type = ADD;

    constructor(public payload: { assets: Asset[], tableType?: TableTypes, files?: any, project_currency_id?: number }) { }
}

export class AddCompleteAction implements Action {
    readonly type = ADD_COMPLETE;

    constructor(public payload: { assets: Asset[], tableType?: TableTypes, event_id?: number }) { }
}

export class AddFailedAction implements Action {
    readonly type = ADD_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class AddFromTransactionAction implements Action {
    readonly type = ADD_FROM_TRANSACTION;

    constructor(public payload: { transaction_list: { id: number, cost: string }[], properties: any }) { }
}

export class AddFromTransactionCompleteAction implements Action {
    readonly type = ADD_FROM_TRANSACTION_COMPLETE;

    constructor() { }
}

export class AddFromTransactionFailedAction implements Action {
    readonly type = ADD_FROM_TRANSACTION_FAILED

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class AddForEventCompleteAction implements Action {
    readonly type = ADD_FOR_EVENT_COMPLETE;

    constructor(public payload: { assets: Asset[], eventId: number }) { }
}

export class AddPhotoAction implements Action {
    readonly type = ADD_PHOTO;

    constructor(public payload: { asset_id: number, photos: Photo[] }) { }
}

export class AddPhotosAction implements Action {
    readonly type = ADD_PHOTOS;

    constructor(public payload: { assets: number[], photos: Photo[] }) { }
}

export class DeletePhotoAction implements Action {
    readonly type = DELETE_PHOTO;

    constructor(public payload: { photo: Photo }) { }
}

export class DeletePhotoCompleteAction implements Action {
    readonly type = DELETE_PHOTO_COMPLETE;

    constructor(public payload: Photo) { }
}

export class DeletePhotoFailedAction implements Action {
    readonly type = DELETE_PHOTO_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DownloadPhotoAction implements Action {
    readonly type = DOWNLOAD_PHOTO;

    constructor(public payload: { photo: Photo }) { }
}

export class DownloadPhotoCompleteAction implements Action {
    readonly type = DOWNLOAD_PHOTO_COMPLETE;

    constructor(public payload: { photo: Blob, fileName: string }) { }
}

export class DownloadPhotoFailedAction implements Action {
    readonly type = DOWNLOAD_PHOTO_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class DuplicateAssetAction implements Action {
    readonly type = DUPLICATE_ASSET;

    constructor(public payload: { assetId: number, copies: number }) { }
}

export class DuplicateAssetCompleteAction implements Action {
    readonly type = DUPLICATE_ASSET_COMPLETE;

    constructor(public payload: { assetId: number, assets: Asset[] }) { }
}

export class DuplicateAssetFailedAction implements Action {
    readonly type = DUPLICATE_ASSET_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class RotatePhotoAction implements Action {
    readonly type = ROTATE_PHOTO;

    constructor(public payload: { photo: Photo, rotation: number }) { }
}

export class RotatePhotoCompleteAction implements Action {
    readonly type = ROTATE_PHOTO_COMPLETE;

    constructor(public payload: Asset) { }
}

export class RotatePhotoFailedAction implements Action {
    readonly type = ROTATE_PHOTO_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class SetMainPhotoAction implements Action {
    readonly type = SET_MAIN_PHOTO;

    constructor(public payload: { asset_id: number, photo_id: number }) { }
}

export class SetMainPhotoCompleteAction implements Action {
    readonly type = SET_MAIN_PHOTO_COMPLETE;

    constructor(public payload: Asset) { }
}

export class SetMainPhotoFailedAction implements Action {
    readonly type = SET_MAIN_PHOTO_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class GetAction implements Action {
    readonly type = GET;

    constructor(public payload: number) { }
}

export class GetCompleteAction implements Action {
    readonly type = GET_COMPLETE;

    constructor(public payload: Asset) { }
}

export class GetFailedAction implements Action {
    readonly type = GET_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByFranchiseAction implements Action {
    readonly type = LIST_BY_FRANCHISE;

    constructor(public payload: { franchiseId: number, options: ListOptions }) { }
}

export class ListByFranchiseCompleteAction implements Action {
    readonly type = LIST_BY_FRANCHISE_COMPLETE;

    constructor(public payload: { franchiseId: number, totalCount: number, assets: Asset[], options: ListOptions }) { }
}

export class ListByFranchiseFailedAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByFranchiseFiltersAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FILTERS;

    constructor(public payload: { franchiseId: number, assetTypes: AssetTypes }) { }
}

export class ListByFranchiseFiltersCompleteAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FILTERS_COMPLETE;

    constructor(public payload: { franchiseId: number, columnFilters: ColumnFilterOptions, columnFiltersForReview: ColumnFilterOptions }) { }
}

export class ListByFranchiseFiltersFailedAction implements Action {
    readonly type = LIST_BY_FRANCHISE_FILTERS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

/**
 * sync is used on the division asset index to force a refresh instead of caching
 * 'sync'ing in asset hub ^_^
 */
export class ListByDivisionAction implements Action {
    readonly type = LIST_BY_DIVISION;

    constructor(public payload: { divisionId: number, options: ListOptions, sync?: boolean }) { }
}

export class ListByDivisionCompleteAction implements Action {
    readonly type = LIST_BY_DIVISION_COMPLETE;

    constructor(public payload: { divisionId: number, totalCount, assets: Asset[], options: ListOptions }) { }
}

export class ListByDivisionFailedAction implements Action {
    readonly type = LIST_BY_DIVISION_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByDivisionFiltersAction implements Action {
    readonly type = LIST_BY_DIVISION_FILTERS;

    constructor(public payload: { divisionId: number, assetTypes: AssetTypes }) { }
}

export class ListByDivisionFiltersCompleteAction implements Action {
    readonly type = LIST_BY_DIVISION_FILTERS_COMPLETE;

    constructor(public payload: { divisionId: number, columnFilters: ColumnFilterOptions }) { }
}

export class ListByDivisionFiltersFailedAction implements Action {
    readonly type = LIST_BY_DIVISION_FILTERS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByEventAction implements Action {
    readonly type = LIST_BY_EVENT;

    constructor(public payload: { eventId: number, options: ListOptions }) { }
}

export class ListByEventCompleteAction implements Action {
    readonly type = LIST_BY_EVENT_COMPLETE;

    constructor(public payload: { eventId: number, totalCount: number, assets: Asset[], options: ListOptions }) { }
}

export class ListByEventFailedAction implements Action {
    readonly type = LIST_BY_EVENT_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByEventFiltersAction implements Action {
    readonly type = LIST_BY_EVENT_FILTERS;

    constructor(public payload: { eventId: number }) { }
}

export class ListByEventFiltersCompleteAction implements Action {
    readonly type = LIST_BY_EVENT_FILTERS_COMPLETE;

    constructor(public payload: { eventId: number, columnFilters: ColumnFilterOptions }) { }
}

export class ListByEventFiltersFailedAction implements Action {
    readonly type = LIST_BY_EVENT_FILTERS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByFinalApproverRequestAction implements Action {
    readonly type = LIST_BY_FINAL_APPROVER_REQUEST;

    constructor(public payload: { request_id: number, options: ListOptions }) { }
}

export class ListByFinalApproverRequestCompleteAction implements Action {
    readonly type = LIST_BY_FINAL_APPROVER_REQUEST_COMPLETE;

    constructor(public payload: { assets: Asset[], totalCount: number, requestId: number, requestAssets: RequestAsset[], options: ListOptions }) { }
}

export class ListByFinalApproverRequestFailedAction implements Action {
    readonly type = LIST_BY_FINAL_APPROVER_REQUEST_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByFinalApproverRequestFiltersAction implements Action {
    readonly type = LIST_BY_FINAL_APPROVER_REQUEST_FILTERS;

    constructor(public payload: { request_id: number }) { }
}

export class ListByFinalApproverRequestFiltersCompleteAction implements Action {
    readonly type = LIST_BY_FINAL_APPROVER_REQUEST_FILTERS_COMPLETE;

    constructor(public payload: { requestId: number; columnFilters: ColumnFilterOptions }) { }
}

export class ListByFinalApproverRequestFiltersFailedAction implements Action {
    readonly type = LIST_BY_FINAL_APPROVER_REQUEST_FILTERS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByGroupAction implements Action {
    readonly type = LIST_BY_GROUP;

    constructor(public payload: { groupId: number, options: ListOptions }) { }
}

export class ListByGroupCompleteAction implements Action {
    readonly type = LIST_BY_GROUP_COMPLETE;

    constructor(public payload: { groupId: number, totalCount: number, assets: Asset[], options: ListOptions }) { }
}

export class ListByGroupFailedAction implements Action {
    readonly type = LIST_BY_GROUP_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByGroupAndSubGroupAction implements Action {
    readonly type = LIST_BY_GROUP_AND_SUB_GROUPS;

    constructor(public payload: { groupId: number, options: ListOptions }) { }
}

export class ListByGroupFiltersAction implements Action {
    readonly type = LIST_BY_GROUP_FILTERS;

    constructor(public payload: { groupId: number, options: ListOptions }) { }
}

export class ListByGroupFiltersCompleteAction implements Action {
    readonly type = LIST_BY_GROUP_FILTERS_COMPLETE;

    constructor(public payload: { groupId: number, columnFilters: ColumnFilterOptions }) { }
}

export class ListByGroupFiltersFailedAction implements Action {
    readonly type = LIST_BY_GROUP_FILTERS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByRequestAction implements Action {
    readonly type = LIST_BY_REQUEST;

    constructor(public payload: { request_id: number, options: ListOptions }) { }
}

export class ListByRequestCompleteAction implements Action {
    readonly type = LIST_BY_REQUEST_COMPLETE;

    constructor(public payload: { requestId: number; totalCount: number; assets: Asset[]; requestAssets: RequestAsset[]; options: ListOptions; }) { }
}

export class ListByRequestFailedAction implements Action {
    readonly type = LIST_BY_REQUEST_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByRequestFiltersAction implements Action {
    readonly type = LIST_BY_REQUEST_FILTERS;

    constructor(public payload: { request_id: number }) { }
}

export class ListByRequestFiltersCompleteAction implements Action {
    readonly type = LIST_BY_REQUEST_FILTERS_COMPLETE;

    constructor(public payload: { requestId: number; columnFilters: ColumnFilterOptions }) { }
}

export class ListByRequestFiltersFailedAction implements Action {
    readonly type = LIST_BY_REQUEST_FILTERS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByStudioAction implements Action {
    readonly type = LIST_BY_STUDIO;

    constructor(public payload: { studioId: number, options: ListOptions }) { }
}

export class ListByStudioCompleteAction implements Action {
    readonly type = LIST_BY_STUDIO_COMPLETE;

    constructor(public payload: { studioId: number, totalCount: number, assets: Asset[], options: ListOptions }) { }
}

export class ListByStudioFailedAction implements Action {
    readonly type = LIST_BY_STUDIO_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListByStudioFiltersAction implements Action {
    readonly type = LIST_BY_STUDIO_FILTERS;

    constructor(public payload: { studioId: number }) { }
}

export class ListByStudioFiltersCompleteAction implements Action {
    readonly type = LIST_BY_STUDIO_FILTERS_COMPLETE;

    constructor(public payload: { studioId: number, columnFilters: ColumnFilterOptions }) { }
}

export class ListByStudioFiltersFailedAction implements Action {
    readonly type = LIST_BY_STUDIO_FILTERS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class MatchAssetsAction implements Action {
    readonly type = MATCH_ASSETS;

    constructor(public payload: {
        assetIds: number[],
        franchiseId: number,
        transactionIds: number[],
        isKeepTotalCostFromCT?: boolean,
        isCallingFromMatchReview?: boolean,
        isCallingFromMatchAsset?: boolean
    }) { }
}

export class MatchAssetsCompleteAction implements Action {
    readonly type = MATCH_ASSETS_COMPLETE;

    constructor(public payload) { }
}

export class MatchAssetsFailedAction implements Action {
    readonly type = MATCH_ASSETS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class MergeAssetsAction implements Action {
    readonly type = MERGE_ASSETS;

    constructor(public payload: {
        asset: Asset,
        assetIds: number[], // Asset to be trashed upon merge
        tableType: TableTypes
    }) { }
}

export class MergeAssetsCompleteAction implements Action {
    readonly type = MERGE_ASSETS_COMPLETE;

    constructor(public payload: {
        asset: Asset,
        assetIds: number[], // Asset to be trashed upon merge
        tableType: TableTypes
    }) { }
}

export class MergeAssetsFailedAction implements Action {
    readonly type = MERGE_ASSETS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export interface RemoveTransactionsPayload {
    assetId: number;
    transactionIds: number[];
}

export class RemoveTransactionsAction implements Action {
    readonly type = REMOVE_TRANSACTIONS;

    constructor(public payload: RemoveTransactionsPayload) { }
}

export class RemoveTransactionsCompleteAction implements Action {
    readonly type = REMOVE_TRANSACTIONS_COMPLETE;

    constructor(public payload: RemoveTransactionsPayload) { }
}

export class RemoveTransactionsFailedAction implements Action {
    readonly type = REMOVE_TRANSACTIONS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class SelectAction implements Action {
    readonly type = SELECT;

    constructor(public payload: number) { }
}

export class SelectInlineEditAction implements Action {
    readonly type = SELECT_INLINE_EDIT;

    constructor(public payload: number) { }
}
export class UpdateAction implements Action {
    readonly type = UPDATE;

    constructor(public payload: { assets: Asset[], forceRecalculate?: boolean }) { }
}

export class UpdateCompleteAction implements Action {
    readonly type = UPDATE_COMPLETE;

    constructor(public payload: { assets: Asset[], columnFilters: ColumnFilterOptions, warning?: any }) { }
}

export class UpdateFailedAction implements Action {
    readonly type = UPDATE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}
export class UpdateMultipleAction implements Action {
    readonly type = UPDATE_MULTIPLE;

    constructor(public payload: { assetIdList: number[], studioId?: number, assetMutations: any, forceRecalculate?: boolean }) { }
}

export class UpdateMultipleCompleteAction implements Action {
    readonly type = UPDATE_MULTIPLE_COMPLETE;

    constructor(public payload: { assets: Asset[], columnFilters: ColumnFilterOptions, warning?: any }) { }
}

export class UpdateMultipleFailedAction implements Action {
    readonly type = UPDATE_MULTIPLE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class SelectAssetsShown implements Action {
    readonly type = SELECT_ASSETS_SHOWN;

    constructor(public payload: AssetTypes) { }
}

export class SetAssetLimit implements Action {
    readonly type = SET_ASSET_LIMIT;

    constructor(public payload: number) { }
}
export interface UpdateFieldMultiplePayload {
    studioId: number;
    assetIds: number[];
    field: string;
    value: any;
    overwrite: boolean;
}

export class UpdateFieldMultipleAction implements Action {
    readonly type = UPDATE_FIELD_MULTIPLE;

    constructor(public payload: UpdateFieldMultiplePayload) { }
}

export class UpdateFieldMultipleCompleteAction implements Action {
    readonly type = UPDATE_FIELD_MULTIPLE_COMPLETE;

    constructor(public payload: Asset[]) { }
}

export class UpdateFieldMultipleFailedAction implements Action {
    readonly type = UPDATE_FIELD_MULTIPLE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListBySubGroupAction implements Action {
    readonly type = LIST_BY_SUB_GROUP;

    constructor(public payload: { subGroupId: number, options: ListOptions }) { }
}

export class ListBySubGroupCompleteAction implements Action {
    readonly type = LIST_BY_SUB_GROUP_COMPLETE;

    constructor(public payload: { subGroupId: number, totalCount: number, assets: Asset[], options: ListOptions }) { }
}

export class ListBySubGroupFailedAction implements Action {
    readonly type = LIST_BY_SUB_GROUP_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ListBySubGroupFiltersAction implements Action {
    readonly type = LIST_BY_SUB_GROUP_FILTERS;

    constructor(public payload: { subGroupId: number, options: ListOptions }) { }
}

export class ListBySubGroupFiltersCompleteAction implements Action {
    readonly type = LIST_BY_SUB_GROUP_FILTERS_COMPLETE;

    constructor(public payload: { subGroupId: number, columnFilters: ColumnFilterOptions }) { }
}

export class ListBySubGroupFiltersFailedAction implements Action {
    readonly type = LIST_BY_SUB_GROUP_FILTERS_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ExportCsvStudioAction implements Action {
    readonly type = EXPORT_CSV_BY_STUDIO;

    constructor(public payload: { studioId: number, options: ListOptions }) { }
}

export class ExportCsvStudioCompleteAction implements Action {
    readonly type = EXPORT_CSV_BY_STUDIO_COMPLETE;

    constructor(public payload: {}) { }
}

export class ExportCsvStudioFailedAction implements Action {
    readonly type = EXPORT_CSV_BY_STUDIO_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ExportCsvFranchiseAction implements Action {
    readonly type = EXPORT_CSV_BY_FRANCHISE;

    constructor(public payload: { franchiseId: number, options: ListOptions }) { }
}

export class ExportCsvFranchiseCompleteAction implements Action {
    readonly type = EXPORT_CSV_BY_FRANCHISE_COMPLETE;

    constructor(public payload: {}) { }
}

export class ExportCsvFranchiseFailedAction implements Action {
    readonly type = EXPORT_CSV_BY_FRANCHISE_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class ExportCsvDivisionAction implements Action {
    readonly type = EXPORT_CSV_BY_DIVISION;

    constructor(public payload: { divisionId: number, options: ListOptions }) { }
}

export class ExportCsvDivisionCompleteAction implements Action {
    readonly type = EXPORT_CSV_BY_DIVISION_COMPLETE;

    constructor(public payload: {}) { }
}

export class ExportCsvDivisionFailedAction implements Action {
    readonly type = EXPORT_CSV_BY_DIVISION_FAILED;

    constructor(public payload: { error: HttpErrorResponse }) { }
}


export type Actions
    = AddAction
    | AddCompleteAction
    | AddFailedAction
    | AddForEventCompleteAction
    | AddPhotoAction
    | AddPhotosAction
    | DeletePhotoAction
    | DeletePhotoCompleteAction
    | DeletePhotoFailedAction
    | DownloadPhotoAction
    | DownloadPhotoCompleteAction
    | DownloadPhotoFailedAction
    | DuplicateAssetAction
    | DuplicateAssetCompleteAction
    | DuplicateAssetFailedAction
    | RotatePhotoAction
    | RotatePhotoCompleteAction
    | RotatePhotoFailedAction
    | SetMainPhotoAction
    | SetMainPhotoCompleteAction
    | SetMainPhotoFailedAction
    | GetAction
    | GetCompleteAction
    | GetFailedAction
    | ListByFranchiseAction
    | ListByFranchiseCompleteAction
    | ListByFranchiseFailedAction
    | ListByFranchiseFiltersAction
    | ListByFranchiseFiltersCompleteAction
    | ListByFranchiseFiltersFailedAction
    | ListByDivisionAction
    | ListByDivisionCompleteAction
    | ListByDivisionFailedAction
    | ListByDivisionFiltersAction
    | ListByDivisionFiltersCompleteAction
    | ListByDivisionFiltersFailedAction
    | ListByEventAction
    | ListByEventCompleteAction
    | ListByEventFailedAction
    | ListByEventFiltersAction
    | ListByEventFiltersCompleteAction
    | ListByEventFiltersFailedAction
    | ListByFinalApproverRequestAction
    | ListByFinalApproverRequestCompleteAction
    | ListByFinalApproverRequestFailedAction
    | ListByFinalApproverRequestFiltersAction
    | ListByFinalApproverRequestFiltersCompleteAction
    | ListByFinalApproverRequestFiltersFailedAction
    | ListByGroupAction
    | ListByGroupCompleteAction
    | ListByGroupFailedAction
    | ListByGroupFiltersAction
    | ListByGroupFiltersCompleteAction
    | ListByGroupFiltersFailedAction
    | ListByRequestAction
    | ListByRequestCompleteAction
    | ListByRequestFailedAction
    | ListByRequestFiltersAction
    | ListByRequestFiltersCompleteAction
    | ListByRequestFiltersFailedAction
    | ListByStudioAction
    | ListByStudioCompleteAction
    | ListByStudioFailedAction
    | ListByStudioFiltersAction
    | ListByStudioFiltersCompleteAction
    | ListByStudioFiltersFailedAction
    | MatchAssetsAction
    | MatchAssetsCompleteAction
    | MatchAssetsFailedAction
    | MergeAssetsAction
    | MergeAssetsCompleteAction
    | MergeAssetsFailedAction
    | RemoveTransactionsAction
    | RemoveTransactionsCompleteAction
    | RemoveTransactionsFailedAction
    | SelectAction
    | SelectInlineEditAction
    | UpdateAction
    | UpdateCompleteAction
    | UpdateFailedAction
    | UpdateMultipleAction
    | UpdateMultipleCompleteAction
    | UpdateMultipleFailedAction
    | SelectAssetsShown
    | SetAssetLimit
    | UpdateFieldMultipleAction
    | UpdateFieldMultipleCompleteAction
    | UpdateFieldMultipleFailedAction
    | ListBySubGroupAction
    | ListBySubGroupCompleteAction
    | ListBySubGroupFailedAction
    | ListBySubGroupFiltersAction
    | ListBySubGroupFiltersCompleteAction
    | ListBySubGroupFiltersFailedAction
    | ListByGroupAndSubGroupAction
    | ExportCsvStudioAction
    | ExportCsvStudioCompleteAction
    | ExportCsvStudioFailedAction
    | ExportCsvFranchiseAction
    | ExportCsvFranchiseCompleteAction
    | ExportCsvFranchiseFailedAction
    | AddFromTransactionAction
    | AddFromTransactionCompleteAction
    | AddFromTransactionFailedAction
